import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { deleteBanner, editBanner, getBanner, postBanner } from '../../service/api';
import Cookies from 'js-cookie';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { AsideAdmin } from './asideAdmin';
import { HeaderAdmin } from './headerAdmin';
import { Banner as BannerTypes } from '../../shared/types';

export const Banner = () => {
    const navigate = useNavigate()
    const [result, setResult] = useState<BannerTypes>()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [obj, setObj] = useState<any>({
        id: 0,
        titulo: "",
        url: "",
        situacao: true,
        posicao: "1",
        data_inicio: "",
        data_fim: "",
        arquivo: "",
        isMobile: false
    });

    useEffect(()=> {
        const loginCookies = Cookies.get('token');
        if(loginCookies === undefined) {
            navigate('/admin')
        }
        return
    },[navigate])

    const buscaBanners = async () => {
        try {
            const infos = await getBanner()
            setResult(infos?.data);
        } catch (error) {
            //
        }
    }
    useEffect(() => {
        buscaBanners();
    },[])

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setObj({
          ...obj,
          [name]: value,
        });
      };

      const handleSubmit = async (e: { preventDefault: () => void; }) => {
        if(isEdit) {
            const envio = {
                id: obj.id,
                titulo: obj.titulo,
                url: obj.url,
                situacao: obj.situacao ? "true" : "false",
                posicao: obj.posicao,
                data_inicio: obj.data_inicio,
                data_fim: obj.data_fim,
                // arquivo: obj.arquivo,
                isMobile: obj.isMobile ? "true" : "false",
            }
            await editBanner(envio)
        } else {
            e.preventDefault();
            const formData = new FormData();
            formData.append('arquivo', obj.arquivo);
            formData.append('titulo', obj.titulo);
            formData.append('url', obj.url);
            formData.append('situacao', obj.situacao === false ? 'false' : 'true');
            formData.append('data_inicio',  obj.data_inicio);
            formData.append('data_fim',  obj.data_fim);
            formData.append('isMobile',  obj.isMobile ? "true" : "false");
            formData.append('posicao',  "1");
    
                await postBanner(formData)
        }

        window.location.reload()
        toast.success('Informação salva com sucesso!');
      };

    return (
        <div>
            <dialog id="modal_banner" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Cadastro de banner</h3>
                    <div>
                        <div className='flex flex-col gap-5 mt-5'>
                            <div>
                                <h3>Titulo:</h3>
                                <input onChange={handleInputChange} value={obj.titulo} name='titulo' type="text" placeholder="Titulo" className="input input-bordered w-full bg-white" />
                            </div>
                            <div>
                                <h3>Data início:</h3>
                                <input value={isEdit ? obj.data_inicio?.substr(0, 10) : obj.data_inicio} onChange={handleInputChange} name='data_inicio' type="date" className="input input-bordered w-full bg-white" />
                            </div>
                            <div>
                                <h3>Data fim:</h3>
                                <input value={isEdit ? obj.data_fim?.substr(0, 10) : obj.data_fim} onChange={handleInputChange} name='data_fim' type="date" className="input input-bordered w-full bg-white" />
                            </div>
                            {!isEdit ? (
                                <div>
                                    <h3>Arquivo:</h3>
                                    <input onChange={(event: any) => {
                                        const file = event.target.files[0];
                                        setObj({
                                            ...obj,
                                            arquivo: file,
                                        });
                                    }} name='arquivo' type="file" className="input input-bordered w-full bg-white" />
                                    <p className="text-sm">Desktop: 1920px de largura por 600px de altura. (.WEBP recomendado)</p>
                                    <p className="text-sm">Mobile: 400px de largura por 650px de altura. (.WEBP recomendado)</p>

                                </div>
                            ) : null}
                            <div className='grid grid-cols-2'>
                                <div className='flex gap-3'>
                                    <h3>Mobile:</h3>
                                    <input checked={obj.isMobile} onChange={(event) => {
                                        setObj({
                                            ...obj,
                                            isMobile: event.target.checked,
                                        });
                                    }} name='isMobile' type="checkbox" className="checkbox checkbox-primary" />
                                </div>
                                <div className='flex gap-3'>
                                    <h3>Situação:</h3>
                                    <input checked={obj.situacao} onChange={(event) => {
                                        setObj({
                                            ...obj,
                                            situacao: event.target.checked,
                                        });
                                    }} name='situacao' type="checkbox" className="checkbox checkbox-primary" />
                                </div>
                            </div>

                            <button onClick={handleSubmit} className='btn btn-primary text-white'>Salvar</button>
                        </div>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => {window.location.reload()}}>close</button>
                </form>
            </dialog>
            <div className='flex flex-col absolute w-full z-0'>
                <HeaderAdmin />
                <AsideAdmin />
            </div>
            <div className='p-28'>
                <div className='flex items-center gap-3'>
                    <i className="ri-dashboard-3-line ri-3x"></i>
                    <h1 className='font-semibold text-2xl'>Painel</h1>
                </div>
                <hr className='w-full h-px' />
                <div className='flex justify-between items-center ml-5 mb-2 z-10 relative'>
                    <div className='flex mt-5 items-center gap-2'>
                        <i className="ri-layout-column-fill ri-2x"></i>
                        <h3 className='text-lg font-semibold'>Banner</h3>
                    </div>
                    <button className="btn btn-primary text-white" onClick={()=> {
                        const modal = document.getElementById('modal_banner') as HTMLDialogElement;;

                        if (modal) {
                          modal.showModal();
                        } else {
                          console.error("Element with ID 'modal_proposta' not found.");
                        }
                    }}>Cadastrar Banner</button>
                </div>
                <div className='gap-14 p-5 z-10 relative'>
                <div className="overflow-x-auto">
                    <table className="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Dispositivo</th>
                            <th>Data fim</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {result?.banner.map((x) => {

                            const data = new Date(x.data_fim);

                            const dia = String(data.getDate()).padStart(2, '0');
                            const mes = String(data.getMonth() + 1).padStart(2, '0');
                            const ano = data.getFullYear();

                            const dataFormatadaFim = `${dia}/${mes}/${ano}`;

                            return (
                            <tr className="hover:bg-base-100">
                                <th>{x.id}</th>
                                <td>{x.titulo}</td>
                                <td>{x.isMobile === false ? "Desktop" : "Mobile"}</td>
                                <td>{dataFormatadaFim}</td>
                                <td className=''>
                                    <i onClick={async() => {
                                    await deleteBanner(x.id)
                                    window.location.reload()
                                }} className="ri-delete-bin-line ri-lg cursor-pointer text-red-700"></i>
                                    <i onClick={() => {
                                        setIsEdit(true)
                                        setObj(x)
                                        const modal = document.getElementById('modal_banner') as HTMLDialogElement;

                                        if (modal) {
                                          modal.showModal();
                                        } else {
                                          console.error("Element with ID 'modal_proposta' not found.");
                                        }
                                    }} className="ri-pencil-line ri-lg cursor-pointer ml-2"></i>
                                </td>
                            </tr>
                        )})}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    )
}