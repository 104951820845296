import { useContext, useEffect, useState } from "react";
import { Endereco } from "../shared/utils/constants";
import { Email, Endereco as EnderecoType, InfosLoja } from "../shared/types";
import { Email as EmailDictionary, Endereco as EnderecoDictionary, Telefone as TelefoneDictionary, InfosLoja as InfosLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { getEmails, getEnderecos, getInfosLoja, postEmail, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { toast } from "react-toastify";
import { Resolver, useForm } from "react-hook-form";

type FormValues = {
    nome: string,
    telefone: string,
    email: string,
    cidade: string,
    mensagem: string,
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: values.nome && values.telefone && values.email && values.cidade && values.mensagem ? values : {},
      errors: {
        ...(values.nome ? {} : { nome: { type: "required", message: "Preencha este campo." } }),
        ...(values.email ? {} : { email: { type: "required", message: "Preencha este campo." } }),
        ...(values.email && !emailRegex.test(values.email) ? { email: { type: "pattern", message: "Email inválido" } } : {}),
        ...(values.telefone ? {} : { telefone: { type: "required", message: "Preencha este campo." } }),
        ...(values.cidade ? {} : { cidade: { type: "required", message: "Preencha este campo." } }),
        ...(values.mensagem ? {} : { mensagem: { type: "required", message: "Preencha este campo." } }),
      },
    };
  };

export const Contato = () => {

    const { telefone, ip, email } = useContext(AuthContext);

    const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm<FormValues>({ resolver })
    const celularValue = watch("telefone");

    const [loja, setLoja] = useState<InfosLoja>();
    const [endereco, setEndereco] = useState<EnderecoType[]>([])
    const [emailState, setEmail] = useState<Email[]>([])
    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    const onSubmit = (data: any) => {
        postEmail({
            to: email,
            replyTo: data.email,
            subject: "Contato",
            html: {
                nome: data.nome,
                telefone: data.telefone,
                email: data.email,
                cidade: data.cidade,
                mensagem: data.mensagem,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          toast.success('Mensagem enviada com sucesso!')
          reset();
    }

    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetEmail = async () => {
        try {
            const result = await getEmails()
            setEmail(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }
    
    useEffect(() => {
        handleGetInfosLoja();
        handleGetEmail();
        handleGetEndereco();
    },[])

    useEffect(() => {
        // Aplica a formatação ao valor do campo celular
    if (celularValue) {
        const apenasDigitos = celularValue.replace(/[^\d]/g, ''); // Remove não dígitos
        let celularFormatado = apenasDigitos;

        // Aplica a formatação apenas se houver 11 dígitos
        if (apenasDigitos.length === 11) {
        celularFormatado = apenasDigitos.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }

        // Define o valor formatado no campo celular
        setValue("telefone", celularFormatado, { shouldValidate: true });
    }
    }, [celularValue]);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    
    return (
        <div className="px-28 py-16 flex bg-secondary flex-col gap-14 mobile:px-3 mobile:gap-5">
            {/* Titulo */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-accent font-semibold">NOSSOS MEIOS DE CONTATO</h1>
                <hr className="h-0.5 bg-accent w-full" />
            </div>

            <div className="flex gap-12 mobile:flex-col mobile:gap-5">
            {/* Formulário de contato */}
            <form onSubmit={handleSubmit(onSubmit)} className="shadow flex grow bg-[#0c0c0c]">
            <div className="p-10 gap-5 flex flex-col mobile:px-3 mobile:py-8 mobile:w-full">
                    <h6 className="text-accent">Nos envie um e-mail</h6>

                    <div>
                        <input id='nome' {...register("nome")} type="text" placeholder="Nome" className="input input-bordered w-full" />
                        {errors?.nome && <p className='text-error text-xs absolute'>{errors.nome.message}</p>}
                    </div>

                    <div>
                        <input id='telefone' {...register("telefone")} type="text" placeholder="Telefone" className="input input-bordered w-full" />
                        {errors?.telefone && <p className='text-error text-xs absolute'>{errors.telefone.message}</p>}
                    </div>

                    <div>
                        <input id='email' {...register("email")} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                        {errors?.email && <p className='text-error text-xs absolute'>{errors.email.message}</p>}
                    </div>

                    <div>
                        <input id='cidade' {...register("cidade")} type="text" placeholder="Cidade" className="input input-bordered w-full" />
                        {errors?.cidade && <p className='text-error text-xs absolute'>{errors.cidade.message}</p>}
                    </div>

                    <div>
                        <textarea id='mensagem' {...register("mensagem")} placeholder="Escreva sua mensagem" className="textarea textarea-bordered w-full min-w-[580px] mobile:min-w-fit" />
                        {errors?.mensagem && <p className='text-error text-xs absolute'>{errors.mensagem.message}</p>}
                    </div>
                    <button className="btn btn-primary text-white font-bold" type="submit">Enviar mensagem</button>

                </div>
                {/* Mapa do endereço */}
                {isMobile() ? null : (
                    <div className="w-full">
                        <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                )}
            </form>

            {/* Lista de contatos */}
            <div className="shadow p-10 gap-7 flex flex-col mobile:px-3 mobile:py-8 bg-[#0c0c0c] text-accent">
                <h1 className="">Ou se preferir, entre em contato utilizando:</h1>

                <div className="flex flex-col gap-3">
                    <h2>Números</h2>
                    <div className="flex flex-col gap-1">
                        {telefoneWhats.map((x) => (
                                <div key={x.telefone} className="flex items-center gap-3 cursor-pointer" onClick={() => {
                                    postWhats({
                                        ip: ip,
                                        dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                        codVeiculo: 0
                                    })
                                    window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                }}>
                                    <i className="ri-whatsapp-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                        {telefoneRamal.map((x) => (
                            <div key={x.telefone} className="flex items-center gap-3 cursor-pointer" onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                <i className="ri-phone-line ri-lg"></i>
                                <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <h2>E-mails</h2>
                    <div className="flex flex-col gap-1">
                        {emailState.map((x) => (
                            <div key={x.email} className="flex items-center gap-3 cursor-pointer" onClick={() => window.open(`mailto:${x[EmailDictionary.EMAIL]}`, "_blank")}>
                                <i className="ri-mail-send-line ri-lg"></i>
                                <h3>{x[EmailDictionary.EMAIL]}</h3>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <h2>Localização</h2>
                    <div className="flex flex-col gap-1">
                        <div className="flex flex-col gap-1 self-start">
                            <div className="flex items-center gap-3">
                                <i className="ri-map-2-line ri-lg"></i>
                                <div>
                                    <h3>{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h3>
                                    <h3>{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <h2>Redes sociais</h2>
                    <div className="flex flex-row gap-7">
                        <i onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.FACEBOOK], "_blank")} className="ri-facebook-circle-fill ri-lg cursor-pointer"></i>
                        <i onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")} className="ri-instagram-line ri-lg cursor-pointer"></i>
                    </div>
                </div>

            </div>

            </div>
        </div>
    )
}