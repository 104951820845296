import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { isMobile } from '../shared/utils/javascript';

import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../shared/context/auth';
import { Endereco, Veiculo } from '../shared/types';
import { getEnderecos, getEstoque } from '../service/api';
import { Endereco as EnderecoDictionary, Telefone as TelefoneDictionary } from '../shared/utils/dictionary';
import Select from 'react-select';

export const Header = () => {

    const { telefone } = useContext(AuthContext);
    const navigate = useNavigate();
    const [estoque, setEstoque] = useState<Veiculo[]>([]);
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [busca, setBusca] = useState<boolean>(false)
    const [optionsMarcaModelo, setOptionsMarcaModelo] = useState([{value: "Busque por marca ou modelo", label: "Busque por marca ou modelo" }]);
    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '100px',
            minWidth: '400px',
            height: '25px',
            paddingLeft: '10px',
            minHeigth: '25px'
        }),
    };
    const customStylesMobile = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '100px',
            minWidth: '80vw',
            height: '25px',
            paddingLeft: '10px',
            minHeigth: '25px'
        }),
    };

    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }

    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetEstoque = async () => {
        try {
            const result = await getEstoque()
            setEstoque(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    const handleSelectChange = (selectedOption: any) => {
        const paramsObj = { 
            busca: selectedOption.value,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    useEffect(() => {
        handleGetEndereco();
        handleGetEstoque();
    },[])

    useEffect(() => {
        const marca = estoque.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        });
        const marcaResult = listaMarca.map((x) => {
            return {value: x, label: x }
        })
    
        const modelo = estoque.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        });
        const modeloResult = listaModelo.map((x) => {
            return {value: x, label: x }
        })
        setOptionsMarcaModelo([...marcaResult, ...modeloResult])
    },[estoque])

    return (
        <div className='mobile:flex mobile:bg-black mobile:justify-center'>
            <div className="bg-black px-24 py-5 flex items-start flex-col text-accent border-b border-b-[#3b3b3b] mobile:border-none mobile:px-3 mobile:py-3 mobile:justify-between">
                <div className='flex justify-between w-full items-center mobile:justify-center'>
                    <img className='cursor-pointer object-cover max-h-32' onClick={() => navigate('/')} src={Logo} alt="Logo" />
                    {!isMobile() && <div className='flex gap-20 items-center'>
                        <div className='flex gap-2 cursor-pointer' onClick={() => window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")}>
                            <i className="ri-phone-fill text-[#ff2e17] ri-lg"></i>
                            <div>
                                <h3 className='font-bold'><span className='font-light'>Fale</span> conosco</h3>
                                <h3>{telefoneWhats?.telefone}</h3>
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            <i className="ri-map-pin-2-fill text-[#ff2e17] ri-lg"></i>
                            <div>
                                <h3 className='font-bold'><span className='font-light'>Nossa</span> localização</h3>
                                <h3>{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h3>
                                <h3>{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h3>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className='bg-black border-b-2 border-[#0c0c0c] px-24 py-3 mobile:justify-center mobile:items-center mobile:flex mobile:border-none mobile:px-3 mobile:w-fit'>
                {isMobile() ? (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" aria-label="Hamburguer" className="btn text-white outline-none border-none"><i className="ri-menu-line ri-3x"></i></div>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                        <li aria-label="Início"><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2></li>
                        <li aria-label="Sobre nós"><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2></li>
                        <li aria-label="Estoque"><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2></li>
                        <li aria-label="Financiamento"><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                        <li aria-label="Avaliação"><h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2></li>
                        <li aria-label="Contato"><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                        </ul>
                    </div>
                ) : (
                    <div className='flex justify-between  min-h-10 items-center'>
                        <div className='text-white flex items-center gap-16'>
                            <h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2>
                            <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2>
                            <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2>
                            <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2>
                            <h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2>
                            <h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2>
                        </div>
                        <div>
                            {busca ? (
                                <div className='flex items-center gap-3'>
                                    <Select
                                        styles={isMobile() ? customStylesMobile : customStyles}
                                        aria-labelledby="Montserrat"
                                        inputId="Montserrat"
                                        name="select-marca-modelo"
                                        placeholder="Busque por marca ou modelo"
                                        onChange={handleSelectChange}
                                        options={optionsMarcaModelo}
                                    />
                                    <div>
                                        <i className="ri-search-line ri-lg bg-white rounded-full p-1 cursor-pointer" onClick={() => setBusca(!busca)}></i>
                                    </div>
                                </div>
                            ) : (
                                <i className="ri-search-line ri-lg bg-white rounded-full p-1 cursor-pointer" onClick={() => setBusca(!busca)}></i>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}