import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.webp'

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const navigate = useNavigate();

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const cambio = veiculo[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col bg-[#fefefe] text-black mobile:min-w-[95vw]" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <img loading="lazy" src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover w-screen h-auto" onClick={handleNavigate} />
            ) : (
                <img loading="lazy" src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover w-screen h-auto" onClick={handleNavigate} />
            )}
            <div className="py-4 px-6 flex flex-col gap-3">
                <h2><span className="font-bold text-xl">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]}</span> {veiculo[VeiculoDictionary.VERSAO]}</h2>
                <div>
                    <h1 className="text-[#666666] font-bold">
                        {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </h1>
                </div>
            </div>
                <div className="flex justify-between h-full px-8 py-4 bg-[#e6e6e6]">
                    <div className="grid grid-cols-2 w-full gap-3">
                        <h2 className="flex gap-3 items-center"><i className="ri-speed-up-fill ri-lg" /> {Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}</h2>
                        <h2 className="flex gap-3 items-center"><i className="ri-git-merge-fill ri-lg" />{cambio?.[VeiculoDictionary.DESCRICAO_OPCIONAIS] ? cambio?.[VeiculoDictionary.DESCRICAO_OPCIONAIS] : "-"}</h2>
                        <h2 className="flex gap-3 items-center"><i className="ri-calendar-2-line ri-lg" /> {veiculo[VeiculoDictionary.ANO] ? veiculo[VeiculoDictionary.ANO] : "-"}</h2>
                        <h2 className="flex gap-3 items-center"><i className="ri-gas-station-fill ri-lg" /> {veiculo?.[VeiculoDictionary.COMBUSTIVEL] ? veiculo?.[VeiculoDictionary.COMBUSTIVEL] : "-"}</h2>
                    </div>
                </div>
        </div>
    )
}