import { useContext, useEffect, useState } from "react";
import { Email, Endereco, InfosLoja, NomeLoja } from "../shared/types";
import { Endereco as EnderecoDictionary, Telefone as TelefoneDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { useNavigate } from "react-router-dom";

import logoLitoral from '../assets/logoLitoral.webp'
import { getEmails, getEnderecos, getInfosLoja, getLoja, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";
import { Endereco as EnderecoConstante } from "../shared/utils/constants";

export const Footer = () => {

    const { telefone, ip } = useContext(AuthContext);

    const navigate = useNavigate()
    const [loja, setLoja] = useState<InfosLoja>();
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [email, setEmail] = useState<Email[]>([])
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()

    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }

    useEffect(() => {
        handleGetInfosLoja();
    },[])

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }
    const handleGetEmail = async () => {
        try {
            const result = await getEmails()
            setEmail(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    useEffect(() => {
        handleGetEmail();
        handleGetEndereco();
        handleGetNomeLoja();
    },[])

    return (
        <div className="bg-black text-white">
            <div className="grid grid-cols-2 min-h-60 justify-center gap-10 mobile:grid-cols-1 mobile:gap-2">
                <div className="w-full mobile:h-80">
                    <iframe title='Mapa' src={EnderecoConstante.link} width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="py-8 px-16 flex flex-col gap-5 mobile:px-5 mobile:text-center">
                    <div className="flex gap-3 mobile:flex-col mobile:items-center">
                        <div className="flex gap-3">
                            <i className="ri-map-pin-2-fill ri-lg"></i>
                            <h2 className="text-xl mobile:text-sm">Nossa Localização:</h2>
                        </div>
                        <h2 className="text-xl mobile:text-sm">{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]} {endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h2>
                    </div>
                    <div className="flex gap-3 mobile:flex-col mobile:items-center">
                        <div className="flex gap-3">
                            <i className="ri-whatsapp-line ri-lg"></i>
                            <h2 className="text-xl mobile:text-sm">Nossa Telefone:</h2>
                        </div>
                        <div>
                            {telefoneRamal.map((x, i) => (
                                <div key={i}>
                                    {i === telefoneRamal.length - 1 ? (
                                        <div className="flex gap-2">
                                            <h2 onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")} className="text-xl mobile:text-sm cursor-pointer">{x.telefone}</h2>
                                            <h2 className="items-end flex text-xl mobile:text-sm cursor-pointer" onClick={() => {
                                                postWhats({
                                                    ip: ip,
                                                    dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                                    lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                                    codVeiculo: 0
                                                })
                                                window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                            }}>| {telefoneWhats?.telefone}</h2>
                                        </div>
                                    ) : (
                                        <h2 onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")} className="text-xl mobile:text-sm cursor-pointer">{x.telefone}</h2>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex gap-3 mobile:flex-col mobile:items-center">
                        <div className="flex gap-3">
                            <i className="ri-time-line ri-lg"></i>
                            <h2 className="text-xl mobile:text-sm">Horário Atendimento:</h2>
                        </div>
                        <div>
                            <h2 className="text-xl mobile:text-sm cursor-pointer">Segunda à Sexta {loja?.loja[DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.HORARIO_SEG]}</h2>
                            <h2 className="text-xl mobile:text-sm cursor-pointer">Sàbado {loja?.loja[DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.HORARIO_SAB]}</h2>
                        </div>
                    </div>
                </div>           
            </div>
            <div className="bg-neutral py-2 flex items-center justify-center">
                {isMobile() ? (
                    <div className="flex flex-col items-center px-3">
                        <p className="flex items-center gap-2 leading-5 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</p>
                        <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-16" src={logoLitoral} alt="Litoral Car" />
                        <span onClick={() => navigate('/termos')} className="underline mt-3 cursor-pointer">Politica de privacidade e termos de uso</span>
                    </div>
                ) : (
                    <p className="flex items-center gap-2 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline cursor-pointer">Politica de privacidade e termos de uso</span></p>
                )}
            </div>
        </div>
    )
}