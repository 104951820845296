import { Carousel } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { InfosLoja, Slider, Veiculo } from "../shared/types";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo.webp'
import instagram from '../assets/instagram.webp'
import axios from "axios";
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary } from "../shared/utils/dictionary";
import { buscarSlide, getEstoque, getInfosLoja } from "../service/api";
import Select from 'react-select';
const Home: React.FC = () => {
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null);
    const [posts, setPosts] = useState([]);
    const [optionsMM, setOptionsMM] = useState([{value: "Busque por marca ou modelo", label: "Busque por marca ou modelo" }]);
    const [optionsMarca, setOptionsMarca] = useState([{img: "", label: "" }]);
    const [slide, setSlide] = useState<Slider[]>([]);
    const [loja, setLoja] = useState<InfosLoja>();
    const [estoque, setEstoque] = useState<Veiculo[]>([]);
    const [wait, setWait] = useState(false);
    const [isMouseM, setIsMouseMoveWithinThreshold] = useState(true);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [scrollLeft, setScrollLeft] = useState(0);
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const estilosHidden = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const customStyles = {
      control: (provided: any) => ({
          ...provided,
          cursor: 'pointer',
          borderRadius: '0px',
          minWidth: isMobile() ? '80vw' : '500px',
          height: '25px',
          paddingLeft: '10px',
          minHeigth: '25px',
        }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        backgroundColor: '#de2712'
      }),          
      indicatorSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: '#de2712'
      }),          
  };
  useEffect(() => {
    if( !isMobile()) {
      let scrollInterval: NodeJS.Timeout;
      const handleScroll = () => {
        if (scrollRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
          if (scrollLeft + clientWidth >= scrollWidth && !wait) {
            setWait(true);
            setTimeout(() => {
              if (scrollRef.current) {
                scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
                setWait(false);
              }
            }, 5000);
          }
        }
      };
      const startScrollInterval = () => {
        scrollInterval = setInterval(() => {
          if (scrollRef.current && !wait) {
            scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
          }
        }, 5000);
      };
      const stopScrollInterval = () => {
        clearInterval(scrollInterval);
      };
      startScrollInterval();
      if (scrollRef.current) {
        scrollRef.current.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeEventListener('scroll', handleScroll);
        }
        stopScrollInterval();
      };
    }
  }, [wait]);
    useEffect(() => {
      const fetchInstagramPosts = async () => {
        try {
          const response = await axios.get(
            `https://graph.instagram.com/me/media?fields=id,caption,media_url,thumbnail_url&access_token=${process.env.REACT_APP_TOKEN_INSTA}&limit=6`
          );
          setPosts(response.data.data);
        } catch (error) {
          console.error('Erro ao buscar os posts do Instagram:', error);
        }
      };
      fetchInstagramPosts();
    }, []);
    const handleGetSlide = async () => {
        const result = await buscarSlide();
        setSlide(result?.data)
    }
    const handleGetEstoque = async () => {
    try {
        const result = await getEstoque()
        setEstoque(result?.data.retorno ?? []) 
    } catch {
        //
    }
    }
    const handleGetInfosLoja = async () => {
        const result = await getInfosLoja()
        setLoja(result?.data)
    }
    const handleSelectChange = (selectedOption: any) => {
      const paramsObj = { 
          busca: selectedOption.value,
      };
      const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      const urlComObjeto = `/estoque?params=${paramsString}`;
      navigate(urlComObjeto)
  };
    useEffect(() => {
        handleGetSlide();
        handleGetInfosLoja();
        handleGetEstoque();
    },[])
    useEffect(() => {
      const marca = estoque.map((x) => {
          return x.marca
      })
      const listaMarca = marca.filter((element, index) => {
          return marca.indexOf(element) === index;
      });
      const marcaResult = listaMarca.map((x) => {
          return {value: x, label: x }
      })
      const modelo = estoque.map((x) => {
          return x.modelo
      })
      const listaModelo = modelo.filter((element, index) => {
          return modelo.indexOf(element) === index;
      });
      const modeloResult = listaModelo.map((x) => {
          return {value: x, label: x }
      })
      setOptionsMM([...marcaResult, ...modeloResult])
      if(!isMobile()) {
        const marca = estoque.map((x) => {
          return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        const marcasObjetos = listaMarca.map(marca => ({
          img: `https://www.litoralcar.com.br/marcaswebapp/logo-${marca.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-')}.webp`,
          label: marca
        }));
        setOptionsMarca(marcasObjetos)
      }
  },[estoque])
  const handleNavigateFilterSelect = async (selectedOption: any) => {
    const paramsObj = { 
      categoria: "",
      marca: selectedOption,
      modelo: "",
      precoDe: "",
      precoAte: "",
    };
    const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
    const urlComObjeto = `/estoque?params=${paramsString}`;
    navigate(urlComObjeto)
};
    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    const findBanner = () => {
        const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
        const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 
        return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    return (
        <div className="bg-secondary">
            <div className="h-[64vh] sm:h-[64vh] 2xl:h-[64vh] mobile:h-[30vh] mobile:relative">
                <Carousel theme={findBanner().length <= 1 ? estilosHidden : estilos}>
                {findBanner()?.map((x: Slider) => (
                    <img loading="lazy" key={x.id} className='object-cover h-full w-screen' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
                ))}
                </Carousel>
            </div>
            <div className="flex bg-[#0c0c0c] px-40 py-8 gap-16 shadow justify-center items-center w-full mobile:py-4 mobile:px-3">
              <div className="flex flex-col gap-5 mobile:gap-2">
                <h2 className="text-accent text-xl font-normal mobile:text-lg">Qual veículo você <span className="font-bold">está buscando?</span></h2>
                <Select
                  styles={customStyles}
                  aria-labelledby="Montserrat"
                  inputId="Montserrat"
                  name="select-marca-modelo"
                  placeholder="Busque por marca ou modelo"
                  onChange={handleSelectChange}
                  aria-label="Busque por marca ou modelo"
                  options={optionsMM}
                />
              </div>
              <div className="flex w-full gap-10 items-center overflow-x-scroll overflow-y-hidden scroll-hidden mobile:hidden" ref={scrollRef}
                onMouseDown={(e) => {
                  setIsDown(true);
                  setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
                  setScrollLeft(scrollRef.current?.scrollLeft || 0);
                }}
                onMouseLeave={() => {
                  setIsDown(false);
                  setIsMouseMoveWithinThreshold(true);
                }}
                onMouseUp={() => {
                  setIsDown(false);
                  if (isMouseM) {
                    handleNavigateFilterSelect(selectedOption);
                  }
                  setIsMouseMoveWithinThreshold(true);
                }}
                onMouseMove={(e) => {
                  if (!isDown || !scrollRef.current) return;
                  e.preventDefault();
                  const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
                  const walk = (x - startX) * 1;
                  scrollRef.current.scrollLeft = scrollLeft - walk;
                  if (Math.abs(e.pageX - startX) > 5) {
                    setIsMouseMoveWithinThreshold(false);
                  }
                }}
              >
                {optionsMarca.map((x) => (
                  <div key={x.label} className="cursor-pointer bg-white min-w-28 min-h-28 w-28 h-28 flex items-center justify-center rounded-md hover:brightness-110" onMouseDown={() => setSelectedOption(x.label)}>
                    <img loading="lazy" className="min-w-20 max-w-20 w-20 h-auto p-1" src={x.img} alt={x.label} />
                  </div>
                ))}
              </div>
            </div>
            <div className="py-8 flex flex-col justify-center items-center text-accent">
                <h1 className="font-light text-2xl text-center mobile:text-xl">Alguns destaques na <span className="font-bold">AK7 AUTOMÓVEIS</span></h1>
                <hr className="h-0.5 bg-accent w-48 mt-5 mobile:mt-3" />
            </div>
            <div className="flex flex-col gap-7 justify-center items-center pb-6 mobile:pb-2 mobile:gap-8">
                <div className="grid grid-cols-3 px-40 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5">
                    {estoque?.slice(0,3).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button aria-label="Ver todos os veículos" onClick={() => navigate('/estoque')} className="btn btn-primary text-black rounded-lg text-lg px-16 w-90">Ver todos os veículos</button>
            </div>
            <div className="text-accent bg-[#0c0c0c] flex items-center justify-center gap-72 py-10 mobile:gap-8 mobile:flex-col-reverse mobile:mt-6">
                {!isMobile() && <img loading="lazy" src={instagram} alt="Instagram" className="max-w-lg cursor-pointer object-cover mobile:max-w-sm" onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")} />}
                <div className="flex flex-col gap-8 justify-center items-center">
                    <div className="flex gap-5 items-center">
                        <img loading="lazy" src={logo} alt="Logo" className="object-cover max-w-36" />
                        <div className="flex flex-col items-center cursor-pointer" onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")}>
                            <h1 className="font-semibold mobile:text-sm">SIGA-NOS NO INSTAGRAM</h1>
                            <hr className="h-0.5 bg-accent w-48 mt-3" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-3 mobile:px-2">
                      {posts.map((x : {thumbnail_url: string, media_url: string, caption: string, id: number}) => (
                          <div key={x.id} className="rounded-md max-w-36 max-h-36 min-w-36 min-h-36 overflow-hidden mobile:min-w-12">
                              <img loading="lazy" className="object-cover cursor-pointer mobile:max-w-full" onClick={() =>{window.open(loja?.loja[DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")}} src={x.thumbnail_url ? x.thumbnail_url : x.media_url} alt={x.caption} />
                          </div>
                      ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Home;